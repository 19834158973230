import type { TypeBadge } from '@/atoms/badge/types';
import type { TypeBadgeGraphQL, TypeTrueFalseACF } from '@/lib/graphql/types';

export const badgeTransformer = (
  badge?: TypeBadgeGraphQL,
  lifeStyle?: TypeTrueFalseACF,
): TypeBadge => ({
  outline: Boolean(badge?.outline),
  style: badge?.style ?? 'orange',
  ...(lifeStyle
    ? {
        roundedBottomLeft: true,
        roundedTopLeft: true,
      }
    : {
        fullRounded: true,
      }),
});
