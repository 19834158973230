import { OPERATOR } from '../orders';
import { getDiscountAmount } from '../price';
import { getRealURL } from '../utils';
import { AFFILIATION_NAME_OPERATOR } from './helpers';

import type { TypeGTMProduct } from '@/types';

export const normalizeAlgoliaItem = (
  item: any,
  itemListId?: string,
  itemListName?: string,
  index?: number,
) => {
  let regularPrice = item.regular_price;
  let salePrice = item.sale_price;
  let sku = item.sku;
  if (item.variations.length > 0) {
    regularPrice = item.variations[0].regular_price;
    salePrice = item.variations[0].sale_price;
    sku = !item.sku ? item.variations[0].sku : item.sku;
  }

  const discount = item.on_sale
    ? Number.parseFloat(getDiscountAmount(regularPrice, salePrice).toFixed(2))
    : null;

  // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
  const seller =
    item?.seller?.at(0) !== OPERATOR
      ? (item?.seller?.at(0) ?? null)
      : AFFILIATION_NAME_OPERATOR;

  let itemToAdd: TypeGTMProduct = {
    item_id: sku,
    item_name: item.name,
    // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
    affiliation: seller,
    coupon: null,
    currency: 'EUR',
    discount,
    index: index ?? null,
    item_brand: Array.isArray(item?.marca) ? item.marca[0] : null,
    item_list_id: itemListId !== undefined ? itemListId : null,
    item_list_name: itemListName !== undefined ? itemListName : null,
    item_variant: null,
    price: regularPrice,
    quantity: 1,
    image: item.featured_image?.src ?? null,
    url: item?.path ? getRealURL(item.path) : null,
  };

  if (item.tags.length > 0) {
    let categories: {
      [key: string]: string;
    } = {};

    for (let i = 0; i < item.tags.length; ++i) {
      const catLabel = i === 0 ? 'item_category' : `item_category${i + 1}`;

      categories = {
        ...categories,
        [catLabel]: item.tags[i].name,
      };
    }

    itemToAdd = { ...itemToAdd, ...categories };
  }

  return itemToAdd;
};
