import { Grid } from './Grid';
import { GridItem } from './GridItem';

export type TypeGrid = typeof Grid & {
  Item: typeof GridItem;
};

(Grid as TypeGrid).Item = GridItem;

GridItem.displayName = 'Grid.Item';

export type { TypeGridProps, TypeGridItemProps } from './types';

export default Grid as TypeGrid;
