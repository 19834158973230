import { oneLine } from 'common-tags';

import type { FunctionComponent } from 'react';
import type { TypeGridItemProps } from './types';

/**
 * GridItem
 */
export const GridItem: FunctionComponent<TypeGridItemProps> = ({
  className = '',
  colSpanXXS = 1,
  colSpanXS,
  colSpanSM,
  colSpanMD,
  colSpanLG,
  colSpanXL,
  colSpanXXL,
  rowSpanXXS = 1,
  rowSpanXS,
  rowSpanSM,
  rowSpanMD,
  rowSpanLG,
  rowSpanXL,
  rowSpanXXL,
  children,
}: TypeGridItemProps) => (
  <div
    // prettier-ignore
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    className={oneLine`
      col-span-${colSpanXXS}
      ${colSpanXS ? `xs:col-span-${colSpanXS}` : ''}
      ${colSpanSM ? `sm:col-span-${colSpanSM}` : ''}
      ${colSpanMD ? `md:col-span-${colSpanMD}` : ''}
      ${colSpanLG ? `lg:col-span-${colSpanLG}` : ''}
      ${colSpanXL ? `xl:col-span-${colSpanXL}` : ''}
      ${colSpanXXL ? `2xl:col-span-${colSpanXXL}` : ''}
      row-span-${rowSpanXXS}
      ${rowSpanXS ? `xs:row-span-${rowSpanXS}` : ''}
      ${rowSpanSM ? `sm:row-span-${rowSpanSM}` : ''}
      ${rowSpanMD ? `md:row-span-${rowSpanMD}` : ''}
      ${rowSpanLG ? `lg:row-span-${rowSpanLG}` : ''}
      ${rowSpanXL ? `xl:row-span-${rowSpanXL}` : ''}
      ${rowSpanXXL ? `2xl:row-span-${rowSpanXXL}` : ''}
      ${className}
    `}
  >
    {children}
  </div>
);
