import { getRealURL, isNumber, isOn, isSimple, toCurrency } from '@/lib/utils';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import type { TypeProduct } from '@/types';
import type { TypeCartProductGraphQL } from '../../types';
import type { TypeImageGQL } from '../order/types';

type WoosbDiscountType = 'none' | 'percentage' | 'amount';

interface WoosbDiscount {
  type: WoosbDiscountType;
  amount: number | null;
  percentage: number | null;
}

interface WoosbDiscountVariant extends WoosbDiscount {
  quantity: number;
}

export type Woosb = {
  discount: WoosbDiscount;
  discountByVariant: Array<WoosbDiscountVariant> | null;
  limitEach: {
    min: number | null;
    max: number | null;
    active: boolean;
  };
  limitWhole: {
    min: number | null;
    max: number | null;
  };
  variants: Array<{
    quantity: number;
  }>;
  optionalProducts: boolean;
  products: Array<
    Omit<
      TypeCartProductGraphQL,
      | 'id'
      | 'databaseId'
      | 'description'
      | 'customTemplate'
      | 'shippingClasses'
      | 'productCategories'
      | 'metaData'
      | 'caracteriSticasDelProducto'
      | 'link'
      | 'image'
      | 'sellerInfo'
    > & {
      id: number;
      quantity: number;
      uri: string;
      image: { src: string; alt: string };
      type: TypeProduct;
      brand: string | undefined;
    }
  >;
  autoCalculatePrice: boolean;
};

export type WoosbParameters = {
  woosbDiscount: string | undefined | null;
  woosbDiscountAmount: string | undefined | null;
  woosbLimitEachMin: string | undefined;
  woosbLimitEachMax: string | undefined;
  woosbLimitEachMinDefault: string | undefined;
  woosbLimitWholeMin: string | undefined;
  woosbLimitWholeMax: string | undefined;
  woosbOptionalProducts: string | undefined;
  woosbIds: Array<{ id: number; quantity: number }> | undefined;
  // TODO: Corregir tipo este no es el correcto
  bundledItems:
    | Array<
        TypeCartProductGraphQL & { uri: string; type: TypeProduct } & {
          caracteriSticasDelProducto: { card: { image: TypeImageGQL } };
        }
      >
    | undefined;
  configurableQuantity: { quantity: number }[];
  customQuantityDiscounts: {
    size: number;
    value: number | null;
    typeDiscount: WoosbDiscountType;
  }[];
  autoCalculatePrice: boolean | undefined;
};

/**
 * Function to filter an object by key or / and value
 * @param obj Object to filter
 * @param fn Function that returns true or false
 * @returns Object filtered
 */
const filterObjectEntries = <T extends Record<string, unknown>>(
  obj: T,
  fn: (key: keyof T, value: T[keyof T]) => boolean,
) =>
  obj
    ? (Object.fromEntries(
        Object.entries(obj).filter(([key, value]) =>
          fn(key as keyof T, value as T[keyof T]),
        ),
      ) as T)
    : null;

/**
 * TODO: corregir el tipo de retorno
 * Function to merge two objects
 * @param a Object to merge
 * @param b Object to merge with a
 * @param fn Function to compare a and b
 * @param aKeys Array of keys to keep from a
 * @param bKeys Array of keys to keep from b
 * @returns Object merged
 */
const merge = <
  T extends Record<string, unknown>,
  Y extends Record<string, unknown>,
>(
  a: T[],
  b: Y[],
  fn: (a: T, b: Y) => boolean,
  aKeys?: Array<keyof T>,
  bKeys?: Array<keyof Y>,
) =>
  a.map((aItem) => {
    const bItem = b.find((bItem) => fn(aItem, bItem));

    return {
      ...(aKeys
        ? filterObjectEntries(aItem, (key) => aKeys?.includes(key))
        : aItem),
      ...(bKeys
        ? bItem && filterObjectEntries(bItem, (key) => bKeys?.includes(key))
        : bItem),
    };
  }) as Array<T & Y>;

export const woosbDiscountTransformer = ({
  woosbDiscount,
  woosbDiscountAmount,
}: Pick<
  WoosbParameters,
  'woosbDiscount' | 'woosbDiscountAmount'
>): Woosb['discount'] => ({
  type: Boolean(woosbDiscountAmount)
    ? 'amount'
    : Boolean(woosbDiscount)
      ? 'percentage'
      : 'none',
  amount: isNumber(woosbDiscountAmount)
    ? parseFloat(woosbDiscountAmount) || null
    : null,
  percentage: isNumber(woosbDiscount)
    ? parseFloat(woosbDiscount) || null
    : null,
});

const getDiscountByVariant = ({
  configurableQuantity,
  customQuantityDiscounts,
  woosbDiscount,
  woosbDiscountAmount,
}: Pick<
  WoosbParameters,
  | 'configurableQuantity'
  | 'customQuantityDiscounts'
  | 'woosbDiscount'
  | 'woosbDiscountAmount'
>) =>
  configurableQuantity?.map(({ quantity }) => {
    const customDiscount = customQuantityDiscounts?.find(
      (d) => d.size === quantity,
    );

    if (!customDiscount || customDiscount?.typeDiscount === 'none')
      return {
        ...woosbDiscountTransformer({
          woosbDiscount,
          woosbDiscountAmount,
        }),
        quantity,
      };

    const { size, value, typeDiscount } = customDiscount;

    return {
      quantity: size,
      type: typeDiscount,
      amount: typeDiscount === 'amount' ? value : null,
      percentage: typeDiscount === 'percentage' ? value : null,
    };
  });

export const woosbTransformer = ({
  woosbDiscount,
  woosbDiscountAmount,
  woosbLimitEachMin,
  woosbLimitEachMax,
  woosbLimitEachMinDefault,
  woosbLimitWholeMin,
  woosbLimitWholeMax,
  woosbOptionalProducts,
  woosbIds,
  bundledItems,
  configurableQuantity,
  customQuantityDiscounts,
  autoCalculatePrice,
}: WoosbParameters): Woosb => {
  const discountByVariant =
    getDiscountByVariant({
      configurableQuantity,
      customQuantityDiscounts,
      woosbDiscount,
      woosbDiscountAmount,
    }) ?? null;
  const discount =
    discountByVariant?.at(0) ??
    woosbDiscountTransformer({ woosbDiscount, woosbDiscountAmount });

  return {
    autoCalculatePrice: Boolean(autoCalculatePrice),
    variants: configurableQuantity?.map(({ quantity }) => ({ quantity })) ?? [],
    discount,
    discountByVariant,
    limitEach: {
      min: isNumber(woosbLimitEachMin) ? parseFloat(woosbLimitEachMin) : 0,
      max: isNumber(woosbLimitEachMax) ? parseFloat(woosbLimitEachMax) : null,
      active: isOn(woosbLimitEachMinDefault),
    },
    limitWhole: {
      min: isNumber(woosbLimitWholeMin) ? parseFloat(woosbLimitWholeMin) : 1,
      max: isNumber(woosbLimitWholeMax) ? parseFloat(woosbLimitWholeMax) : null,
    },
    optionalProducts: isOn(woosbOptionalProducts),
    products:
      Array.isArray(woosbIds) && Array.isArray(bundledItems)
        ? merge(
            bundledItems,
            woosbIds,
            (a, b) => a?.databaseId === b.id,
            [
              'name',
              'uri',
              'primaryCategory',
              'sku',
              'type',
              'image',
              'regularPrice',
              'price',
              'onSale',
              'stockStatus',
              'stockQuantity',
              'caracteriSticasDelProducto',
              'databaseId',
            ],
            ['id', 'quantity'],
          )
            // TODO: ELIMINAR CUANDO HAYA SOPORTE PARA PRODUCTOS QUE NO SEAN SIMPLES
            .filter((p) => isSimple(p.type))
            .map(
              ({
                caracteriSticasDelProducto,
                primaryCategory: { name, path },
                ...product
              }) => {
                const price = product.price?.split('-')?.[0];
                const regularPrice = product.regularPrice?.split('-')?.[0];

                if (!price) {
                  throw new Error(
                    `No price found, file: woosb.ts, product: ${product}`,
                  );
                }

                if (!regularPrice) {
                  throw new Error(
                    `No regularPrice found, file: woosb.ts, product: ${product}`,
                  );
                }

                return {
                  ...product,
                  primaryCategory: {
                    name,
                    path,
                  },
                  brand:
                    caracteriSticasDelProducto?.datosDeProducto?.marca?.at(0)
                      ?.slug ?? '',
                  image: {
                    src:
                      caracteriSticasDelProducto?.card.image?.sourceUrl ||
                      product.image?.sourceUrl ||
                      FallBackIMG.src,
                    alt:
                      caracteriSticasDelProducto?.card.image?.altText ||
                      product.image?.altText ||
                      product.name,
                  },
                  price: toCurrency(price),
                  ...(product.onSale && {
                    regularPrice: toCurrency(regularPrice),
                  }),
                  uri: getRealURL(product.uri),
                };
              },
            )
        : [],
  };
};
