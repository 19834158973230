import { normalizeAlgoliaItem } from './normalizeAlgoliaItem';

export const normalizeAlgoliaItems = (
  items: any[],
  itemListId?: string,
  itemListName?: string,
) =>
  items.map((item, index) =>
    normalizeAlgoliaItem(item, itemListId, itemListName, index),
  );
